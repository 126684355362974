.uploadForm {
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#taskName {
  width: 400px;
}

.uploadBox {
  /* padding-bottom: 20px; */
  margin: 20px;
}

.parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid gray;
}

.ant-upload-list-item {
  /* border: 1px dashed rgb(84, 84, 151) !important;s */
  font-size: 1.02rem;
}

.ant-upload-list-item-card-actions .anticon {
  color: rgb(239 10 10 / 45%);
}

/* .uploadBox .ant-upload-list-item-name {
  font-size: 1.1rem;
  padding-bottom: 20px;
} */

.upload-steps {
  /* float: right; */
  /* margin-right: 40px; */
  font-size: 80%;
  display: flex;
  align-items: center;
  /* text-align: center; */
  /* margin: auto; */
  /* width: 95%; */
}

.upload-steps span {
  display: inline-block;
  margin: 0px 10px 0px 0px;
  /* color: #bbb; */
}

.stretch-arrow {
  transform: scaleY(1.2);
}

.upload-steps .dotActive {
  color: #4093d1;
  position: relative;
  padding: 3px 0px 3px 30px;
}

.dotDisabled {
  position: relative;
  padding: 3px 0px 3px 30px;
}

.dotDisabled:before {
  background: #d3d3d3;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 4px;
  left: 0px;
  width: 18px;
  height: 18px;
  display: block;
  content: '';
  border-radius: 50%;
}

.dotActive:before {
  background: #4093d1;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 4px;
  left: 0px;
  width: 18px;
  height: 18px;
  display: block;
  content: '';
  border-radius: 50%;
}

.footnote {
  font-size: 0.85em;
  /* position: absolute; */
  padding: 0px 2% 15px;
  font-size: 72%;
  float: left;
  /* text-align: right; */
  /* width: 100%; */
  color: #757474;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 3px !important;
  overflow-wrap: break-word;
}

.fieldMapTable {
  overflow: auto;
  height: 295px;
}

.fieldMapTable table > thead > tr > th {
  /* background-color: #d9e7f9 !important; */
  /* font-size: 0.5em !important; */
  text-align: center;
}

.fieldMapTable table > tbody > tr:nth-child(1) {
  background-color: #f0f0f0 !important;
}
.fieldMapTable table > tbody > tr:nth-child(1) > td {
  border: 0.5px solid #cacaca4a !important;
}
.fieldMapTable table > thead > tr > th .ant-select-selector {
  /* background-color: #d9e7f9 !important; */
  color: var(--primary);
  text-align: left;
  /* border: none !important; */
  height: 40px !important;
  padding: 5px 9px !important;
  line-height: 40px;
  /* border: 1px solid var(--blue) !important; */
}
/*color: 868889*/
.duplicateValue {
  /* font-weight: bold; */
  /* color: #fd00007a; */
  text-decoration: underline;
}

.taskStatsTable table > tbody > tr:nth-last-child(1) {
  background-color: #fafafa;
  color: #454545;
  font-weight: bold;
  text-align: left;
}
.taskListTable table > thead > tr {
  line-height: 5px;
  background-color: none !important;
}
.taskListTable table > thead > tr > th {
  background: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #545454;
  border-bottom: 2px solid #f0f0f0;
}
.taskListTable table > tbody > tr > td {
  position: relative;
  padding: 7px !important;
  overflow-wrap: break-word;
  border-bottom: 2px solid #f0f0f0;
}
.taskListTable table > tbody > tr > td:first-child {
  position: relative;
  padding: 0 !important;
}
.taskListTable table > tbody > tr {
  border-radius: 4px;
}

.taskListTable table > tbody > tr:hover > td {
  background-color: #bbbbbb2c !important;
}

.font-big {
  /* font-size: 1.0625rem; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  margin-left: 5px;
  color: #545454 !important;
}

.font-big-disabled {
  /* font-size: 1.0625rem; */
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  margin-left: 5px;
  color: #545454 !important;
}

a.font-big:hover {
  text-decoration: underline !important;
}

a.downloadBtn:hover {
  text-decoration: underline !important;
}

a.statsBtn:hover {
  text-decoration: underline !important;
}

a.actionBtn:hover {
  text-decoration: underline !important;
}

.actionBtnAlert {
  color: #f5222d !important;
}

a.actionBtnAlert:hover {
  color: #f5222d;
  text-decoration: underline !important;
}

.linkIcon {
  color: var(--blue);
}

.linkIconAlert {
  color: #f5222d;
}

.linkIconDisabled {
  color: #00000040;
  cursor: not-allowed;
  user-select: none;
}

.threeDots {
  height: 5px;
  width: 5px;
  margin: 3px auto;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.threedotContainer {
  float: right;
  margin-right: 5px;
}

.footer {
  padding: 0px 2% 15px;
  font-size: 80%;
  float: left;
  text-align: right;
  width: 100%;
  color: #757474;
  height: 35px;
}

.stats-taskname {
  font-size: 0.9rem;
  color: #757474;
}

.stats-title {
  width: 80%;
  padding: 2px 5px;

  /* BOTH of the following are required for text-overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fieldLabel {
  font-weight: 500;
  color: #545454;
  /* font-family: 'Roboto-Medium'; */
}

.titleInBody {
  font-size: 1.02rem;
  color: #454545;
  /* padding-left: 15px; */
}

.statsIcon {
  cursor: pointer !important;
  height: 24px;
  width: 24px;
  color: #454545;
  transition: 0.3s;
  font-size: 16px;
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex !important;
}

.statsIconDiv {
  color: #454545;
  padding-right: 15px;
}

.popoverContent {
  /* display: block; */
  /* width: 400px; */
  width: calc(40vw - 180px);
  /* height: 100px; */
}
.statsDescriptionTable table > tbody {
  font-size: 0.8vw;
}
.statsDescriptionTable table > tbody > tr:hover > td {
  background-color: #bbbbbb2c !important;
  cursor: pointer;
  color: #454545;
  font-weight: 400;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  white-space: normal;
}

.recordProcessbarContent {
  width: 237px;
  border-radius: 5px;
}

.recordsProcessTitle {
  text-transform: capitalize;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  color: #7a7a7a;
}

.processRecordCount {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7a7a7a;
}

.recordsProcessBar {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.fieldValidationDefaultIcon {
  height: 0.9vw;
  width: 0.9vw;
  border-radius: 50%;
  display: inline-block;
  border-block-color: #bbb;
  border-radius: 50%;
  border: 1px solid #bbb;
  border-color: #bbb;
}

.fieldValidationTitleDefaultIcon {
  height: 9px;
  width: 9px;
  background-color: rgb(155, 155, 155);
  border: 1px solid rgb(150, 150, 150);
  border-radius: 50%;
  display: inline-block;
}

.fieldMapTable .ant-select-selection-placeholder {
  padding-right: 18px;
  /* background: #f0fafa; */
  font-style: italic;
  color: #a2a2a2;
}

.fieldMapTable .ant-select-selector {
  padding: 0px !important;
  /* border: 1px solid gray !important; */
}
.fieldMapTable
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid var(--blue);
}
.listViewCol .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid var(--blue);
}

.add-task-modal .ant-modal-body {
  padding: 10px 40px 5px;
}

.add-task-modal .ant-modal-header {
  padding: 1px 6px;
  border-bottom: none;
}

.addTask-upload-steps {
  align-items: center;
  border-bottom: 0.5px solid #f0f0f0;
  /* width: 94%;
  margin-left: 20px; */
}

.addTask-upload-steps .ant-steps-navigation:active .ant-steps-item::after {
  border: 1px solid #0f3eab;
}

.ant-steps-navigation .ant-steps-item::after {
  content: none;
}
.ant-steps-navigation .ant-steps-item::before {
  background: none !important;
}

.addTask-upload-steps .ant-steps-item-title {
  font-size: 13px;
  padding-right: 0px;
  line-height: 32px;
  font-weight: 400;
}

.select-defaultValue .ant-select-selector {
  background: #f0fafa !important;
  color: #8c8585;
}

.task-list-action-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--blue);
}

.btn-Icon {
  color: var(--blue);
  border-color: var(--blue);
}

.upload-add-text {
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px !important;
  color: #7a7a7a;
}

.parent .ant-upload-drag-icon {
  margin-bottom: 5px !important;
}

.parent .ant-upload-hint {
  color: #a3a3a3 !important;
  font-size: 12px !important;
}

.floating-text {
  position: absolute;
  width: 20px;
  margin-top: 57px;
}

.linkText {
  color: #9dd8f7;
}

.text-align-grid {
  display: inline-grid;
}

.taskListTable .ant-table-pagination.ant-pagination {
  width: 100%;
  right: 10px;
  padding: 5px;
  height: 45px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  z-index: 1000;
  transition: 0.5s;
  /* box-shadow: rgba(197, 197, 197, 0.7) 10px 5px 5px; */
}

.footer-btn {
  width: 100px;
}

.footer-cancel-btn {
  margin-right: -20px;
}

/* .footer-btn:hover, */
.footer-btn:focus {
  background-color: var(--blue);
  /* box-shadow: 5px 5px 10px #4293d2; */
  outline: 3px solid #437bde;
  border-radius: 0px;
}

.uploadBox .ant-upload.ant-upload-drag {
  border: 2px dashed #c4c4c4;
  padding: 20px;
}

.uploadBox .ant-upload-list-text-container {
  display: none;
}

.uploadBox .ant-upload.ant-upload-drag:hover {
  border: 2px dashed #545454;
}

.ant-table-thead > tr > th,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 5px !important;
  overflow-wrap: break-word;
}

.successAlert .ant-alert-message,
.ant-alert-success .ant-alert-icon {
  color: #00ab54;
}

.successAlert .ant-alert-icon {
  color: #00ab54;
  font-size: 25px;
}

.successAlert.ant-alert-close-icon .anticon-close {
  font-size: 17px;
  color: #cac7b6;
  font-weight: 700;
}

.infoAlert .ant-alert-message,
.ant-alert-info .ant-alert-icon {
  color: #0073ff;
}

.infoAlert .ant-alert-icon {
  color: #0073ff;
  font-size: 25px;
}
.infoAlert {
  border: 1px solid #78b8ff3d;
}
.warningAlert .ant-alert-message,
.ant-alert-warning .ant-alert-icon {
  color: #ffa300;
}

.warningAlert .ant-alert-icon {
  color: #ffa300;
  font-size: 25px;
}

.errorAlert .ant-alert-message,
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.errorAlert .ant-alert-icon {
  color: #f5222d;
  font-size: 25px;
}
.ant-alert-close-icon .anticon-close {
  /* color: rgba(0, 0, 0, 0.45); */
  transition: color 0.3s;
  font-size: 15px;
  color: #cac7b6;
  font-weight: bold;
}

.TaskSummaryWizard,
.FieldMappingWizard,
.TaskSummaryWizard {
  width: fit-content;
}
.TaskSummaryWizard .ant-steps-item-icon > .ant-steps-icon {
  width: 85px !important;
  margin: auto;
}

.FileUploadWizard .ant-steps-item-icon > .ant-steps-icon {
  width: 65px !important;
}
.FieldMappingWizard .ant-steps-item-icon > .ant-steps-icon {
  width: 75px !important;
}

.displayFlex {
  display: flex;
  justify-content: space-between;
  margin: auto 5px;
}

.userDetailsLabel {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.45);
}

.userDetailsText {
  color: #454545;
  font-size: 14px;
  margin-left: 10px;
}

.creditAvailables-text {
  color: #a6a6a6;
  font-size: 12px;
}

.creditAvailables {
  color: #222;
  font-size: 18px;
  text-align: right;
  margin-right: 5px;
}

.modalStatsTable .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.modalStatsTable .ant-modal-header {
  padding: 10px;
}

.taskStatsTable table > thead tr > :nth-child(1),
.taskStatsTable table > tbody tr > :nth-child(1) {
  border-right: 1px solid transparent !important;
}

.statsDrillDownTable table > tbody tr > :nth-child(1) {
  border-right: 1px solid #d9d9d9a6 !important;
}

.boldText {
  font-weight: 500;
}
.textOverFlow {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taskListDropDownMenu > .ant-dropdown-menu {
  border-radius: 4px;
  margin-right: 30px;
  width: 180px;
  background: #fafafa;
}

.taskListDropDownMenu > .ant-dropdown-arrow {
  margin-left: 14px !important;
}

.ListViewWizard {
  width: -moz-fit-content;
  width: fit-content;
}

.dragfileMask {
  position: fixed;
  top: 130px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: auto;
  background-color: #fff;
  opacity: 0.5;
  display: none;
  border-radius: 4px;
  border: 2px dashed black;
}

.dropFileText {
  position: fixed;
  top: 25%;
  right: 25%;
  bottom: 0;
  left: 40%;
  color: black;
  font-size: 18px;
  z-index: 1000;
  text-align: center;
  margin-top: 150px;
  font-weight: 500;
  display: none;
}

.totalTaskCount {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #545454;
}

.tasklistimg {
  width: 30px;
  height: 30px;
}

.taskPauseIcon:hover img {
  content: url(../../assets/GIF/pause.gif);
  width: 30px;
  height: 30px;
}

.upload-file-lable {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #545454;
  font-family: Roboto-Medium;
}

.creditCardInfo {
  border-radius: 8px;
  height: 60px;
  margin-bottom: 10px;
  border: 0.5px solid #f0f0f0;
  padding: 7px;
  background-color: #fff;
}

.creditsCount {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
}

.labelBoldText {
  font-family: 'Roboto-Medium';
}

.outer-circle {
  background: #ffff;
  border: 1px solid #cacaca;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-top: 4px;
  display: inline-block;
}
.inner-circle {
  position: absolute;
  background: #cacaca;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin: 1px;
}

.fieldMapTable .ant-table-small .ant-table-thead > tr > th {
  background-color: #fff;
  /* border: 1px solid var(--blue); */
}

.fieldMapTable .ant-table-thead > tr > th,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 0px !important;
}

.taskViewRadio {
  margin-bottom: 5px;
  position: absolute;
  right: 10px;
  top: 65px;
  z-index: 1;
}
.listFeildsMargin {
  margin-bottom: 7px;
}

.feildsLable {
  font-family: Roboto-Medium;
  margin-left: 16px;
}

.feildsLableStatus {
  position: absolute;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-top: 7px;
}

.feildsLableMatchBackStatus {
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-top: 7px;
}

.listViewSelect .ant-select-selection-placeholder {
  padding-right: 18px;
  /* background: #f0fafa; */
  font-style: italic;
  color: #a2a2a2;
}
.fieldMappingTaskStep .ant-table-thead {
  font-family: 'Roboto-Medium';
}

.taskSummaryCollapse .ant-collapse-header .ant-collapse-arrow {
  padding: 10px 1px !important;
}

.taskSummaryCollapse .ant-collapse-content-box {
  padding-top: 0px !important;
}

.connectorCards {
  text-align: center;
}
.taskSelectionBtn {
  margin-top: 15px;
}

.taskSelectionCard .ant-modal-body {
  height: 450px;
  padding: 0px;
}

.addTaskCardLayout .ant-card-head-title {
  padding: 0px;
}

.addTaskCardLayout .ant-card-head {
  border-bottom: none;
}
.addTaskCardLayout .ant-card-body {
  height: 450px;
  overflow-y: scroll;
  padding: 10px 40px 5px;
}

.addTaskCardLayout .ant-card-actions {
  background: #f0eded;
  border-top: 1px solid #f0f0f0;
  width: 100%;
}
.addTaskCardLayout .ant-card-actions li {
  float: right;
  width: 100px !important;
  margin-right: 10px;
}

.listViewCards .ant-card-body {
  height: fit-content;
  overflow: hidden;
}

.menuUploadType .ant-dropdown-menu-item {
  color: #fff;
  width: 165px;
  background: var(--primary);
}

.menuUploadType .ant-dropdown-menu-item:hover {
  color: #fff;
  width: 165px;
  background: var(--primary);
}

.uploaded-file-name {
  margin-left: 15px;
  width: 368px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dividerPosition {
  margin: -14px 0px auto;
  position: fixed;
  z-index: 1;
}

.taskNameValidation {
  margin-left: 5.5rem;
  color: var(--warning);
  font-size: 12px;
  margin-bottom: 5px;
}
