.msgText {
  font-size: 14px;
  line-height: 16px;
  color: #7a7a7a;
  font-weight: 400;
}

.msgContent {
  width: 356px;
  height: 130px;
  padding: 10px;
}
