.userProfileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0.9rem;
  color: #fff;
  line-height: 30px;
  text-align: center;
  background: var(--blue);
  /* display: 'inline-block', */
}

.userListTable table > tbody > tr > td,
.userListTable table > thead > tr > th {
  position: relative;
  padding: 7px !important;
  overflow-wrap: break-word;
}

.userListTable table > tbody > tr:hover > td {
  background-color: #bbbbbb2c !important;
}

.userListTable .ant-table-column-sorters {
  padding: 0px;
}

.user-name {
  /* font-size: 1.0625rem; */
  font-size: 1rem;
  color: #3f3d3da6 !important;
}

a.user-name:hover {
  text-decoration: underline !important;
}

.textLabel {
  color: #777;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0px;
}

.textInfo {
  color: #454545;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.threeDotsUser {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 3px 0px 0px 10px;
  /* display: inline-block; */
  cursor: pointer;
}

.linkIcon {
  color: var(--blue);
}

.linkIconDisabled {
  color: #00000040;
  cursor: not-allowed;
  user-select: none;
}

.user-list-action-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--blue);
}

.userListDropDownMenu > .ant-dropdown-menu {
  border-radius: 4px;
  /* margin-right: 20px; */
  width: 160px;
  background: #fafafa;
}

.userListTable .ant-table-pagination.ant-pagination {
  width: 100%;
  right: 10px;
  position: fixed;
  padding: 5px;
  height: 45px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  transition: 0.5s;
}

.userListTable table > thead > tr > th {
  background: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #545454;
  border-bottom: 2px solid #f0f0f0;
}

.userlistimg {
  width: 26px;
  height: 26px;
}

.userEditIcon:hover img {
  content: url(../../assets/GIF/edit-gif.gif);
}

.userResetIcon:hover img {
  content: url(../../assets/GIF/reset.gif);
}

.userForm .ant-form-item-label {
  text-align: left;
}

.userForm .ant-form-item-label > label {
  margin-left: 12px;
}

.userForm
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-left: -12px;
  margin-right: 6px;
}
