.table {
  width: 100%;
  font-size: 1vw;
}

.columnOne {
  width: 220px;
  vertical-align: baseline;
  padding: 5px 0px;
}

.columnTwo {
  /* width: 200px; */
  display: grid;
  padding: 5px 0px;
}

.cursorPointer {
  cursor: pointer;
}

.columnThree {
  width: 363px;
  padding: 5px 0px;
}

.rowHeader {
  background-color: #1010102b;
}

.rowBorder {
  border-bottom: 1px solid #f0f0f0;
  white-space: normal;
}

.notesSize {
  font-size: 0.9vw;
}

.boldFont {
  font-weight: bold;
  color: #454545c5;
  margin-right: 5px;
}

.noteText {
  font-style: italic;
  color: #fd00007a;
}

.radioStyle {
  display: block;
  height: 28px;
  line-height: 20px;
}

.info-icon {
  margin-left: 15px;
}

.content-popover {
  font-size: 0.9vw;
  color: rgb(63 61 61 / 53%);
}

.content-popover-modal {
  /* display: block; */
  /* width: 400px; */
  width: calc(40vw - 180px);
  /* height: 100px; */
  box-shadow: none;
}
.content-popover-modal-dd {
  /* display: block; */
  /* width: 400px; */
  width: calc(45vw - 180px);
  /* height: 100px; */
  box-shadow: none;
}
.subs-cont-popup-dd{
  width: fit-content !important;
}
.enrichmodeCard {
  margin-bottom: 15px;
}
.enrichmodeCard .ant-card-body {
  padding: 15px !important;
  height: 120px !important;
  overflow: hidden !important;
}

.enrichmodeCard-radio {
  margin-left: 20px;
}

.rowWidth {
  width: 95%;
}

.field-text {
  width: 100%;
  vertical-align: baseline;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #545454;
  font-family: Roboto-Medium;
  margin-bottom: 7px;
  /* padding: 5px 0px; */
}
