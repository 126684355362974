#centerBox-forgot-page {
  padding-bottom: 15px;
}
.forgot-header {
  padding: 8px 8px 9px;
  border-bottom: 2px solid transparent;
  text-align: center !important;
}
.forgot-font {
  color: #000;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.forgot-body {
  background: rgb(231, 102, 102);
  padding: 8px 8px 9px;
  border-bottom: 2px solid transparent;
  text-align: center !important;
}
.forgot-footer {
  text-align: center !important;
  padding-bottom: 10px;
}
.light {
  color: #303030;
}
