.white-blocks {
  /* background: #e7f2f5 none repeat scroll 0px 0px; */
  border-radius: 3px;
  float: left;
  position: relative;
  width: 100%;
}
.centerBox {
  background: rgb(255, 255, 255, 0.85) none repeat scroll 0px 0px;
  border-radius: 30px;
  /* box-shadow: rgb(204, 204, 204) 0px 0px 9px; */
  display: block;
  margin: 40px auto 15px;
  width: 350px;
  /* padding-bottom: 15px; */
  margin-top: calc((100vh - 430px) / 2);
}
.loginBox {
  /* border-top: 1px solid rgb(255, 255, 255); */
  /* padding: 0px 30px; */
  /* padding: 10px 25px; */
  padding: 1px 20px;
  /* background: #f5e238 none repeat scroll 0px 0px; */
}

.footer-text {
  padding: 10px 0px;
  font-size: 85%;
  float: left;
  text-align: center;
  /* width: 100%; */
  box-sizing: border-box;
  color: #7a7a7a;
}

.login-input {
  /* background: rgb(252, 250, 248, 0) none repeat scroll 0px 0px; */
  width: 100%;
  position: relative;
  border-radius: 4px;
  height: 35px;
  box-sizing: border-box;
}

.loginBox #login-username {
  color: #454545;
  background: rgba(255, 255, 255, 0) none repeat scroll 0px 0px;
  font-size: 13px;
  margin-left: 5px;
}
.loginBox #login-password {
  color: #454545;
  background: rgba(255, 255, 255, 0) none repeat scroll 0px 0px;
  font-size: 13px;
  margin-left: 5px;
}
.login-button {
  border-radius: 4px;
  text-align: center;
}
.ant-form-item-explain {
  font-size: 85%;
}

.resolutionInfoText {
  /* float: right; */
  position: absolute;
  background: rgb(255, 255, 255, 0.77) none repeat scroll 0px 0px;
  border-radius: 8px;
  display: block;
  margin: 5px;
  width: 350px;
  padding: 15px;
  left: 5px;
  bottom: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  z-index: 1;
  /* opacity: 0.9; */
}

.boldtext {
  font-weight: 600;
}

.bgLayout {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url('../../assets/images/Banner\ background\ 1.png');
  background-repeat: repeat;
}
.loginBlock {
  padding-right: 0 !important;
  height: 100%;
  background-color: white;
  position: absolute;
  right: 0;
  width: 100%;
}

.landingImg {
  /* position: absolute; */
  -webkit-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 2%;
}
.landingImg img {
  width: 64%;
  height: auto;
  margin-top: 4%;
  margin-left: 17%;
}
.loginContent {
  transform: translateY(-50%);
  position: absolute;
  top: 40%;
  width: 70%;
  margin: 45px;
}
.logoRightSide {
  float: right;
  margin-top: 10px;
}

.titleText {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #7a7a7a;
  margin-bottom: 45px;
}

.errorMsg {
  color: #e64e31;
  font-weight: 400;
  position: absolute;
  top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-form-item-icon {
  font-size: 18px;
}

.top-65{
  top: 65px;
}
