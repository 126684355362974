.browser-warning-div {
    width: 100%;
    display: flex;
    background: #DA3F3F;
    position: fixed;
    z-index: 1;
    padding: 12px 18px 12px 18px;
    gap: 12px;
}

.browser-warning-image {
    margin-left: 9px;
    line-height: 24px;
    width: 1.44px;
    height: 8px;
    border-radius: 11.48px;
    color: #FBAC0A;
    /* margin-left: 8px; */
}

.browser-warning-support-image {
    width: 24px;
    height: 24px;
    border-radius: 36px;
    border: 1px solid #FBAC0A;
    background: #FFF7E7;
}

.browser-warning-message {
    color: #FFFFFF;
    line-height: 20px;
    font-size: 14px;
}

.browser-warning-message span {
    font-weight: 700;
    font-size: 16px;
    vertical-align: baseline;
}


.browser-warning-space{
    min-height: 62px;
}

.browser-warning-image-div{
    display: flex;
    align-items: center;
}