.dashboardContent {
  /* padding: 10px 20px; */
  margin-top: 15px;
}
.dashbordCards {
  border-radius: 8px;
  height: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.creditInfoCard {
  border-radius: 8px;
  height: 60px;
  margin-bottom: 10px;
}
.creditCount {
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}

.cardTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
}

.cardBody {
  align-items: center;
  display: flex;
  gap: 8px;
}

.dashboardSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 2.2rem;

  color: #545454;
}

.subTitleText {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 2.2rem;
}

.subText {
  font-size: 0.9rem;
  line-height: 2.2rem;
}

.verticallyCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.textAlignRight {
  text-align: right;
}

.toggleTableIcon {
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 0;
}
.dashbordCards:hover .toggleTableIcon {
  opacity: 1;
}

.needMoreCreditBtn {
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.checkableTagCredits {
  border: 0.5px solid #cecece;
  border-radius: unset !important;
  font-size: 15px !important;
  line-height: 30px;
  height: 32px;
  padding: 0px 16px !important;
  margin-right: 14px !important;
  background: white;
}

.getMoreCreditModal .ant-modal-body {
  padding: 15px;
}

.recordStatsTable table > tbody > tr:nth-last-child(1) {
  background-color: #fafafa;
  color: #454545;
  font-weight: bold;
  text-align: left;
}

.dashbord-piechart .highcharts-root {
  max-width: auto;
  max-height: 250px;
  margin: 5px auto;
}

.teamMemberCarousal {
  width: 95%;
  margin: auto;
}
.teamMemberCarousal .slick-prev {
  border: 1px solid #cacaca;
}
.teamMemberCarousal .slick-next {
  border: 1px solid #cacaca;
}

.container {
  padding: 10px;
}
.slick-track {
  margin: auto;
}
.slick-slide-div {
  padding: 7px 5px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  width: 40px !important;
  /* background: #4d9aab; */
  box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.1);
  /* margin-right: 5px !important; */
  margin: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  margin-top: 15px;
  /* margin-left: 5px; */
  color: #ffffff;
}

.teamList-user-name {
  font-size: 0.9rem;
  line-height: 15px;

  text-align: center;

  color: #545454;
}

.user-email-id {
  font-size: 0.7rem;
  line-height: 15px;

  text-align: center;
  color: #545454;
}
.carousel-root {
  height: 125px;
}

.center-active {
  margin-top: 5px;
  text-align: center;
  /* position: absolute; */
  /* margin: auto; */

  margin-left: -35px;
}
.carousel .center-active {
  display: none;
}

.carousel .selected .center-active {
  display: block;
  /* margin: auto; */
  width: 150px;
}

.total-team-member {
  float: right;
  margin-top: -5px;
}
.total-user {
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;

  color: #545454;
}

.team-label {
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #a2a2a2;
}

.teamMemberCarousel .selected .slick-slide-div {
  width: 70px !important;
  height: 70px;
  font-size: 24px;
  padding: 20px 17px;
  margin-top: 1px;
}

.teamMemberCarousel .control-dots {
  display: none;
}
.teamMemberCarousel .carousel-slider .control-arrow {
  /* background: rgba(0, 0, 0, 0.7) !important; */
  height: 30px;
  margin-top: 30px !important;
  font-size: 16px !important;
  color: #000 !important;
  background: #fff !important;
  box-shadow: 2px 2px 10px 4px rgba(80, 75, 75, 0.1) !important;
}

.teamMemberCarousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}
.teamMemberCarousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}
.wrap-text {
  width: 140px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.teamMemberCarousel .slider-wrapper {
  width: 87% !important;
  margin: auto !important;
  height: 125px;
  text-align: center !important;
}

.teamMemberCarousel .carousel-status {
  display: none;
}

.apiKeyGenerationTable .ant-table-tbody > tr > td {
  padding: 5px !important;
}
.apiKeyGenerationTable .ant-table-thead > tr > th {
  padding: 9px !important;
  font-family: Roboto-Medium;
}

.apiKeyInput {
  padding: 4px 0px;
}

.apiKeyGenerationTable .ant-empty-normal {
  margin: 5px;
}
