.mainBox {
  border-radius: 3px;
  float: left;
  position: relative;
  width: 100%;
  padding-bottom: 10px;
}
.middleBox {
  background: rgb(255, 255, 255, 0.85) none repeat scroll 0px 0px;
  display: block;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.lastBox {
  width: 100%;
  float: left;
  margin-top: 10px;
  border-top: 1px solid #e0e4ec;
  padding: 5px 0;
  text-align: center;
}

.DivBlock {
  display: flex;
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mainFrame {
  width: 50%;
  float: left;
}
.mainFrameBody {
  position: relative;
  width: 100%;
  float: left;
  box-sizing: border-box;
  /* background: #fff; */
  line-height: 16px;
  border-radius: 7px;
}
.mainFrameHeader {
  box-sizing: border-box;
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #545454;
  /* border-bottom: 1px solid #82818145; */
  padding: 10px 10px;
}
.mainFrameTitle {
  width: 67%;
  float: left;
  font-family: Roboto-Medium;
}
.mainFrameStats {
  position: relative;
  width: 100%;
  float: left;
  padding: 10px;
}

/* piecharts */
.tooltip {
  z-index: 999;
  padding: 5px;
  background: #ffffff;
  margin-top: 1px;
  margin-left: 1px;
}

/* toggle button  */
.toggle-chart {
  position: absolute;
  top: -31px;
  right: 20px;
  /* display:none; */
  opacity: 0;
}
.mainFrameBody:hover  .toggle-chart {
  /* display:inline-block; */
  opacity: 1;
}

.table-icon {
  cursor: pointer !important;
  height: 24px;
  width: 24px;
  /* color: #454545; */
  transition: 0.3s;
  font-size: 20px;
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex !important;
}

.NDTD {
  margin-top: 66px;
  margin-bottom: 140px;
  vertical-align: middle;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #545454;
}

.statsTitle {
  width: 80%;
  padding: 2px 5px;

  /* BOTH of the following are required for text-overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats-reportId {
  font-size: 0.9rem;
  color: #757474;
}

.lastUpdateText {
  font-size: 85%;
  color: #828181;
}

.statsDrillDownTable table > tbody > tr:nth-last-child(1) {
  background-color: #ffffff !important;
  color: #3f3d3da6 !important;
  font-weight: unset !important;
}

.statsDrillDownTable .ant-spin-container {
  padding-left: 19px;
  padding-right: 4px;
  margin: 5px auto;
}

.statsTaskNameBody {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #545454;
}

.statsTaskName {
  font-size: 1rem;
  color: #454545;
}

.card-stats-body {
  position: relative;
  width: calc(100%);
  float: left;
  box-sizing: border-box;
  background: #fff;
  line-height: 16px;
  border-radius: 7px;
}
.stats-spin {
  margin-top: 145px;
  margin-bottom: 140px;
  vertical-align: middle;
}

.internalRecordstats {
  text-align: center;
  border-color: #d9d9d97a;
  border: 1px solid #d9d9d97a;
  width: 100%;
}

.internalRecordstats th:first-child {
  padding: 5px;
  text-align: left;
}

.tdFirstChild {
  padding: 5px !important;
  text-align: left;
}

.internalRecordstats th {
  padding: 5px;
  border-color: #d9d9d97a;
}
.internalRecordstats td {
  padding: 5px;
  border-color: #d9d9d97a;
}

.tdHide {
  border: 1px solid gray;
  border-top-color: transparent;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}
