.clientProfileImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0.9rem;
  color: #fff;
  line-height: 30px;
  text-align: center;
  background: #2a9fd8;
  /* display: 'inline-block', */
}

.clientListTable {
  margin-bottom: 60px;
}

.clientListTable table > tbody > tr > td,
.clientListTable table > thead > tr > th {
  position: relative;
  padding: 7px !important;
  overflow-wrap: break-word;
}

.clientListTable table > tbody > tr:hover > td {
  background-color: #bbbbbb2c !important;
}

.client-name {
  /* font-size: 1.0625rem; */
  font-size: 1rem;
  color: #3f3d3da6 !important;
}

a.client-name:hover {
  text-decoration: underline !important;
}

.threeDotsClient {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.ccEmailIcon {
  cursor: pointer !important;
  height: 24px;
  width: 24px;
  color: #454545;
  transition: 0.3s;
  font-size: 16px;
  align-items: center !important;
  justify-content: center !important;
  display: inline-flex !important;
}

.clientListTable .ant-table-column-sorters {
  padding: 0px;
}

.linkIcon {
  color: var(--blue);
}

.linkIconDisabled {
  color: #00000040;
  cursor: not-allowed;
  user-select: none;
}

.client-list-action-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: var(--blue);
}

.clientListDropDownMenu > .ant-dropdown-menu {
  border-radius: 4px;
  margin-right: 20px;
  width: 150px;
}

.clientListTable .ant-table-pagination.ant-pagination {
  background-color: white;
  width: 100%;
  right: 10px;
  position: fixed;
  /* padding: 2px 5px 22px 5px; */
  padding: 5px;
  height: 45px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  box-shadow: 0px 0px 10px #80808054;
  z-index: 1000;
  transition: 0.5s;
  /* box-shadow: rgba(197, 197, 197, 0.7) 10px 5px 5px; */
}

.tabsName {
  font-size: 15px;
}

.editClientFooter {
  padding: 10px 16px;
  text-align: right;
  background: #f0eded;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.creditAvailablesTitle {
  color: #bfbfbf;
  font-size: 14px;
  text-align: right;
}

.creditInput .ant-input-number-handler-wrap {
  display: none;
}

.refreshIcon {
  position: absolute;
  right: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.spinLoader {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 40px;
}

.addCreditTab {
  height: 180px;
}

.displayFlex {
  display: flex;
}

.marginRight20 {
  margin-right: 20px;
}
.contextBoxText {
  position: absolute;
  margin: 5px;
  right: 0;
  font-size: 15px;
  font-weight: 500;
}

.clientForm .ant-form-item-label {
  text-align: left;
}

.clientForm .ant-form-item-label > label {
  margin-left: 12px;
}

.clientForm
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-left: -12px;
  margin-right: 6px;
}

.clientTabHeader .ant-tabs-nav {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 92%;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background-color: #ffffff;
  height: 50px;
}
.clientTabHeader .ant-tabs-content-holder {
  margin-top: 60px;
}

.clientModal .ant-modal-close-x {
  background-color: #ffffff;
}

.astrickSign {
  position: absolute;
  margin-top: 8px;
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.errorThrow {
  color: #e5493a;
  font-size: 12px;
  margin-top: -19px;
  /* line-height: 30px; */
  position: absolute;
}

.client_create_container .ant-modal-title{
  font-weight: 700;
  letter-spacing: 0.2px;
}

/* .license_type_container .ant-form-item-control{
  width: 25px;
}

.license_type_container .ant-form-item-no-colon{
  width: 418px;
} */

/* .license_type_container .ant-form-item-label > label{
  width: 418px;
} */

/* .license_count_container .ant-form-item-label{
  width: 245px;
} */

.divider_enrich.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0% !important;
  margin-left: -18px !important;
}
/* .license_count_container .ant-form-item-no-colon{
  
} */

/* .salesforce_org_css .ant-form-item-label{
  padding-left: 7px;
} */

/* .license_edit .ant-form-item-label{
    padding-left: 5px;
    margin-right: 5px;
} */

.salesforce_org_css .info-icon{
 margin-left: 6px !important;
}

.client_edit_container .ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 0% !important;
  margin-left: -17px !important;
}

.client_edit_container .ant-form-item-label > label::after{
  content:'';
}

.create-minus{
  position: absolute !important;
  top: 10px !important;
  left: 98% !important;
}


@media screen and (min-width: 1550px) and (max-width: 1600px)     {
  .license_count_container .ant-form-item-label {
    width: 321px;
}
.license_type_container .ant-form-item-control {
  width: 98px;
}
}

.dd-disabled-create .ant-form-item-control-input{
  border: 1px solid #d9d9d9;
  padding-left: 10px;
}