@font-face {
  font-family: Roboto;
  src: url(assets/Font/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(assets/Font/Roboto-Medium.ttf);
}

body {
  font-family: Roboto !important;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 3px solid #ffffff;
}

.unauthenticated-bg {
  background-image: url(./assets/images/bg_img-01.jpg);
  background-size: calc(100vw - 0px);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.logo {
  float: left;
  padding: 7px 0px;
  /* position: fixed; */
  /* z-index: 1001; */
  /* bottom: 0; */
}
.logo img {
  float: left;
  width: 150px;
}
.site-header {
  background: url(./assets/images/header-bg.jpg) !important;
}
.ant-menu {
  background: none !important;
}

.sectionHeading > span {
  font-size: 1.2rem;
}

.copy-text {
  font-size: 0.8rem;
}

.dfm-header {
  padding: 0 2% 1% 0% !important;
  float: left;
  width: 100%;
  /* background: url(./assets/images/header-bg.jpg) no-repeat 0 0 #fff !important; */
  background-size: 100% !important;
  height: 67px !important;
}
.border-bottom {
  height: 67px !important;
}

span.global-header-right {
  float: right;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  direction: rtl;
}

.fontSize11 {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  color: #a3a3a3;
}

.userProfileName {
  font-weight: bold !important;
}

.downProfileIcon {
  font-size: 10px !important;
  padding: 9px 9px 9px 0px;
}

.userProfileIcon {
  width: 40px !important;
  height: 32px !important;
  border-radius: 5px 0px 0px 5px !important;
  font-size: 0.9rem !important;
  color: #fff;
  line-height: 28px !important;
  text-align: center !important;
  background: #7a7a7a !important;
  margin-left: -2px !important;
  margin-top: -1px !important;
}

.userDropDownMenu > .ant-dropdown-arrow {
  right: 10px !important;
}

.userProfile_btn:hover .userProfileIcon {
  background: var(--blue) !important;
}

.userProfile_btn:focus .userProfileIcon {
  background: var(--blue) !important;
}

.userDropDownMenu > .ant-dropdown-menu {
  border-radius: 4px;
  width: 200px;
}

.userProfile_btn {
  border: 1px solid #70b7e3;
  border-radius: 5px !important;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px !important;
  text-align: left !important;
}

.img-initials {
  /* background-color: #677a7f !important; */
  text-transform: uppercase;
  /* height: 60px;
  width: 60px; */
  text-align: center;
  vertical-align: middle;
  padding: 10px 7px;
  font-size: 25px;
  color: #fff;
}

.userProfileName_text {
  display: inline-block;
  max-width: 110px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  direction: ltr;
  margin: -6px 0px;
}

.label-text {
  color: #454545;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0px;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.site-layout-footer {
  background-color: white;
  width: 100%;
  position: fixed;
  padding: 1px 5px 5px;
  bottom: 0;
  right: 0;
  z-index: 1;
  /* box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8) !important; */
}

.menu-style .ant-menu-item-selected,
.ant-menu-item:hover {
  border-bottom: 3px solid var(--blue) !important;
}

.creditsLoader {
  font-size: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 1px !important;
}

.menuBg {
  background-color: #fafafa !important;
}
.floatRight {
  float: right;
}

.btn-width {
  width: 145px;
}

.footerLayout {
  flex-direction: column;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  position: fixed;
  bottom: 0;
  background-color: white;
  bottom: 0px;
  right: 0;
  margin-bottom: 0px;
  /* z-index: 1000; */
  transition: 0.5s;
  box-shadow: 0px 0px 10px 4px rgba(142, 142, 142, 0.15);
}

.copyRightsText {
  position: fixed;
  bottom: 0px;
  right: 6px;
}

.footer-task-list-new{
  bottom: -16px !important;
  margin-right: 6px !important;
}

#footer-record-enricher{
  height: 55px !important;
}
.customPagination {
  position: fixed;
  height: 40px;
  bottom: 0;
  z-index: 1;
  right: 5px;
  margin-bottom: 0px;
  transition: 0.5s;
  width: 350px;
}

.paginationContent .ant-pagination-prev {
  margin-left: 160px;
  position: absolute;
}

.paginationContent .ant-pagination-next {
  margin-left: 70px;
}

.paginationContent .ant-pagination-simple-pager input {
  height: 32px !important;
  pointer-events: none;
}

.paginationBtn {
  padding: 4px 10px !important;
  border-radius: 0px 4px 4px 0px !important;
}

.paginationContent .ant-pagination-simple-pager {
  width: 115px;
  margin: 0;
}

.loading-center {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
lord-icon {
  width: 30px;
  height: 30px;
}
