.white-block-card {
  background: none repeat scroll 0 0 #fff;
  border-radius: 3px;
  float: left;
  padding: 5px 10px;
  position: relative;
  width: 100%;
  height: calc(100vh - 160px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: auto;
}

.white-block-card .ant-card-body {
  padding: 5px;
}

.inputForm {
  font-size: 14px;
}

.inputForm .ant-form-item-label > label {
  width: 150px;
}

.inputForm .ant-form-item {
  margin-bottom: 15px;
}

.enrich-selection {
  padding: 30px 0px 0px 0px;
  width: 60%;
  margin: auto;
}

.action-btn {
  width: 60%;
  margin: auto;
}

.action-btn .ant-btn.ant-btn-primary{
  margin-top: 30px;
}

.action-btn .ant-btn {
  width: 100%;
  margin: 10px 0px;
  border-radius: 4px;
}

.contact-image {
  float: left;
  padding: 6px 6px 0 0;
  width: 70px;
  height: 70px;
}

.contact-image img {
  width: 100%;
  border-radius: 60px;
}

.contact-icons {
  float: right;
  width: 100px;
  text-align: right;
}

.dividerMargin {
  margin: 5px;
}

.validation-Comp {
  padding: 5px;
  border: 1px solid #f7735e;
  background: rgb(247, 236, 234);
  margin-bottom: 10px;
}
.combination-ul li {
  list-style-type: disc;
  padding: 5px;
  margin-left: 25px;
  color: #dd4b33;
}

.spinLoader {
  position: fixed;
  z-index: 999;
  height: 5em;
  width: 5em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #4093d1;
}

.spinLoader-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.26);
}

.datashow-div {
  /* display: flex;
  padding: 5px 0; */
  color: #868889;
}
/* .field-div {
  line-height: 18px;
  height: 17px;
  overflow: hidden;
} */
.oneThird {
  width: 34%;
  float: left;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.twoThird {
  width: 66%;
  float: left;
}
.font-size-small {
  font-size: 14px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-tab-name {
  max-width: calc(100% - 25px);
  float: left;
}

.contact-name {
  color: #454545;
  font-size: 14px;
}
.repeater {
  width: 100%;
  float: left;
}

/* .repeater :hover {
  width: 100%;
  float: left;
  background-color: #868889;
} */

.iconPadding {
  margin-right: 5px;
  color: #bbbbbb;
}
.rotate {
  transform: rotate(90deg);
}

.field-div {
  line-height: 18px;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.field-div .twoThird span {
  /* max-width: calc(100% - 20px); */
  display: inline-block;
}
.field-div:hover {
  background-color: #f3f3f3;
}

.expand-text {
  position: absolute;
  /* top: 0;
  right: 0;
  display: inline-block;
  font-size: 120%; */
}
.gray {
  color: #868889;
}
.repeater {
  width: 100%;
  float: left;
}
.padding5 {
  padding: 5px 0;
}

.contactEmail .ant-select-selection-item {
  display: inline-block;
  min-width: 100px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.fetchDetailsText {
  font-size: 14px;
  font-weight: 600;
}

.margin0 {
  margin: 0px;
}

.padding0 {
  padding: 0px;
}

.showHideIcons {
  position: absolute;
  right: 0.625rem;
}
.plusMinusIcon {
  font-size: 0.625rem;
  color: rgb(50, 134, 216) !important;
}

.headerTable {
  /* text-align: center; */
  border-color: #d9d9d97a;
  border: 1px solid #d9d9d97a;
  width: 100%;
}
.headerTable th:first-child {
  padding: 3px 5px;
}
.headerTable td:first-child {
  padding: 3px 5px;
}

.link a {
  color: #1e5ad0;
  text-decoration: underline;
}
