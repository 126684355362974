.marketoTabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0px 0;
  padding: 12px 24px;
  text-align: center;
  border: 0.5px solid #ddd;
}
.marketoTabs .ant-tabs-tab-active {
  border-left: 3px solid var(--blue) !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.marketoTabs .ant-tabs-ink-bar {
  background-color: #ddd;
}
.feildsRowMargin {
  margin-bottom: 7px;
}
