#centerBox-reset-page {
  padding-bottom: 15px;
  width: 400px;
}
.reset-header {
  padding: 8px 8px 20px;
  border-bottom: 2px solid transparent;
  text-align: center !important;
}
.reset-form {
  padding-inline-end: 10px;
  padding-inline-start: 10px;
}
.reset-font {
  color: #000;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
.forgot-body {
  background: rgb(231, 102, 102);
  padding: 8px 8px 9px;
  border-bottom: 2px solid transparent;
  text-align: center !important;
}
.light {
  color: #303030;
}

.resetContent {
  margin: 10px 5px;
}
.reset-criteria {
  padding: 10px;
  /* background: rgb(193, 184, 184, 0.85) none repeat scroll 0px 0px; */
  border-radius: 10px;
  display: block;
  margin: 10px auto 5px;
  /* width: 400px; */
}

.token-expire-blocks {
  border-radius: 3px;
  float: left;
  position: relative;
  width: 100%;
  padding-top: 30px;
}
.token-expire-centerBox {
  background: rgb(255, 255, 255, 0.85);
  border-radius: 5px;
  /* border: 1px solid #a9a9a914; */
  /* border-block-end: 3px solid #ffa39e; */
  width: 400px;
  display: block;
  margin: 40px auto 15px;
  margin-top: calc((100vh - 430px) / 2);
}

.token-expire-footer {
  text-align: center !important;
  padding: 10px;
}

.token-expire-footer-text {
  padding: 10px 20px;
  font-size: 85%;
  float: left;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  color: #e6e3e3;
}

.token-expire-message .ant-alert-description {
  padding-left: 7px;
  /* background-color: #cbe2f9; */
  color: #0b5cad;
}

.token-expire-alert {
  background: rgb(255, 255, 255, 0.85);
  border: 2px;
  /* border-bottom: 1px solid #ffa39e; */
}
.token-expire-alert .ant-alert-icon {
  top: 30px;
}
.token-expire-alert .ant-alert-message {
  color: #ae1800;
}

.layout-btn {
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;

  text-align: center;

  color: #ffffff;
}
