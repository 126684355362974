.cardBottomContent {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 85px;
}
.connectorMenu .ant-menu-item {
  height: 45px;
  margin: 0px;
  border: 0.5px solid #ddd !important;
  margin-bottom: 0px !important;
}

.connectorMenu .ant-menu-item::after {
  border-right: none;
}
.connectorMenu .ant-menu-item-selected {
  border-left: 3px solid var(--blue) !important;
  background-color: transparent !important;
  color: #454545 !important;
}
.connectionForm .ant-form-item-label {
  text-align: left;
}

.current-status {
  border-radius: 32px;
  padding: 2px 5px;
  font-size: 11px;
  color: #231f20;
  line-height: 10px;
  /* margin-top: 5px; */
  text-align: center;
  text-decoration: underline dotted;
}
.connectorCards {
  border-radius: 8px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
