@primary-color: #1e5ad0; // primary color for all components
@link-color: #1e5ad0; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #e5493a; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #454545;
@text-color: #7a7a7a; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@layout-header-background: #fff;
@layout-body-background: #fff;

@btn-primary-bg: #1e5ad0;
@btn-border-radius-base: 4px;

@badge-height: 20px;
@badge-height-sm: 14px;
@badge-dot-size: 10px;
@badge-status-size: 10px;
@success-color: #1ade80;

@modal-footer-bg: #f0eded;

:root {
  --blue: #1e5ad0;
  --primary: #1e5ad0;
  --secondary: #6c757d;
  --success: #1ade80;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #ed7325;
  --light: #f8f9fa;
  --dark: #343a40;
  --tr-color: #53f666;
  --text-color: #7a7a7a;
  --high-weightage-text: #545454;
}
